<template>
	<!-- begin:: Footer -->
	<div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
		<div class="d-flex align-items-center justify-content-between" v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
			<div class="text-dark">
				<span class="text-muted font-weight-bold mr-2">
					2021 &nbsp;&copy;&nbsp;
				</span>
				<a href="https://www.prinorquest.com/" target="_blank" class="text-dark-75 text-hover-primary">
					PrinorQuest
				</a>
				<span class="text-muted font-weight-bold mr-2">
					(Ver.: {{ gitHash }})
				</span>
			</div>
			<div class="nav nav-dark">
				<a href="https://www.prinorquest.com/datenschutz" target="_blank" class="nav-link pr-3 pl-0">
					{{ $t('MENU.Misc.privacyPolicy') }}
				</a>
				<a href="https://www.prinorquest.com/impressum" target="_blank" class="nav-link px-3">
					{{ $t('MENU.Misc.legalNotice') }}
				</a>
			</div>
		</div>
	</div>
	<!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'KTFooter',
	computed: {
		...mapGetters(['layoutConfig']),

		/**
		 * Check if footer container is fluid
		 */
		widthFluid() {
			return this.layoutConfig('footer.width') === 'fluid';
		},

		gitHash: function() {
			return process.env.VUE_APP_GIT_HASH;
		},
	},
};
</script>
