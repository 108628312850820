<template>
	<div class="topbar-item" v-if="!!currentUser && !!currentUser.first_name">
		<b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown" no-caret right no-flip text="Actions" v-b-tooltip.hover="''">
			<template v-slot:button-content>
				<div id="kt_quick_user_toggle" class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
					<span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"> {{ $t('MENU.Account.greeting') }}, </span>
					<span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
						{{ currentUser.first_name }}
					</span>
					<span class="symbol symbol-35 symbol-light-success">
						<span class="symbol-label font-size-h5 font-weight-bold">
							{{ currentUser.first_name.charAt(0).toUpperCase() + currentUser.last_name.charAt(0).toUpperCase() }}
						</span>
					</span>
				</div>
			</template>
			<div class="navi navi-hover min-w-md-250px">
				<b-dropdown-text tag="div" class="navi-header font-weight-bold">
					{{ $t('MENU.Account.title') }}
				</b-dropdown-text>
				<b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
				<b-dropdown-text tag="div" class="navi-item">
					<router-link to="/account" v-slot="{ href, navigate, isActive, isExactActive }">
						<a :href="href" class="navi-link" @click="navigate">
							<span class="navi-icon">
								<i class="fas fa-user-cog"></i>
							</span>
							<span class="navi-text">{{ $t('GENERAL.General.settings') }}</span>
						</a>
					</router-link>
				</b-dropdown-text>
				<b-dropdown-text tag="div" class="navi-separator mt-3"></b-dropdown-text>
				<b-dropdown-text tag="div" class="navi-footer">
					<a class="btn btn-light-danger font-weight-bolder d-block" href="#" v-on:click.prevent="onLogout">{{ $t('GENERAL.Buttons.logout') }}</a>
				</b-dropdown-text>
			</div>
			<!--end::Navigation-->
		</b-dropdown>
	</div>
</template>

<script>
import { LOGOUT } from '@/core/services/store/auth.module';
import { mapGetters } from 'vuex';

export default {
	name: 'UserDropdown',
	methods: {
		onLogout: function() {
			this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }));
		},
	},
	computed: {
		...mapGetters(['currentUser']),
	},
};
</script>

<style scoped></style>
