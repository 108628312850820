var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",attrs:{"id":"kt_wrapper"}},[_c('KTHeader'),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",class:_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return (name === 'survey-edit' || name === 'survey-create');
}) ? 'survey-edit-content' : '',attrs:{"id":"kt_content"}},[(_vm.subheaderDisplay)?_c('KTSubheader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"pageActions":_vm.pageActions,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid py-5 py-xl-0"},[_c('div',{class:{
							'container-fluid': _vm.contentFluid,
							container: !_vm.contentFluid,
						}},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)])],1),_c('KTFooter')],1)],1),(_vm.toolbarDisplay)?_c('KTStickyToolbar'):_vm._e(),_c('KTScrollTop')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }