<template>
	<ul class="menu-nav">
		<router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
				<a :href="href" class="menu-link" @click="navigate">
					<span class="menu-text"> Dashboard </span>
				</a>
			</li>
		</router-link>

		<router-link to="/builder" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
				<a :href="href" class="menu-link" @click="navigate">
					<span class="menu-text"> Builder </span>
				</a>
			</li>
		</router-link>

		<li aria-haspopup="true" data-menu-toggle="click" class="menu-item menu-item-submenu menu-item-open-dropdown" v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }">
			<a href="#" class="menu-link menu-toggle">
				<span class="menu-text"> Vue Bootstrap </span>
			</a>
			<div class="menu-submenu menu-submenu-fixed">
				<div class="menu-subnav megamenu" style="max-width: 1000px;">
					<ul class="menu-content">
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Task Reports </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vue-bootstrap/alert" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Alert </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/badge" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Badge </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/button" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Button </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/button-group" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Button Group </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/button-toolbar" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Button Toolbar </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/card" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Card </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/carousel" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Carousel </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Profit Margins </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vue-bootstrap/collapse" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Collapse </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/dropdown" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Dropdown </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/embed" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Embed </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/form" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/form-checkbox" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form Checkbox </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/form-file" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form File </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/form-group" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form Group </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/form-input" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form Input </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Staff Management </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vue-bootstrap/form-radio" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form Radio </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/form-select" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form Select </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/form-textarea" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Form Textarea </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/image" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Image </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/input-group" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Input Group </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/jumbotron" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Jumbotron </span>
										</a>
									</li>
								</router-link>
								<router-link to="layout-grid-system" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Layout Grid System </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Tools </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vue-bootstrap/link" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Link </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/list-group" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> List Group </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/media" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Media </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/modal" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Modal </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/nav" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Nav </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/navbar" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Navbar </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/pagination" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Pagination </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/pagination-nav" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Pagination Nav </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Misc </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vue-bootstrap/notify-popover" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Popover </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/progress" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Progress </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/progress-spinner" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Spinner </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/table" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Table </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/tabs" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Tabs </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/notify-toasts" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Toasts </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vue-bootstrap/notify-tooltip" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Tooltip </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</li>
		<li aria-haspopup="true" data-menu-toggle="click" class="menu-item menu-item-submenu menu-item-open-dropdown" v-bind:class="{ 'menu-item-active': hasActiveChildren('vuetify') }">
			<a href="#" class="menu-link menu-toggle">
				<span class="menu-text"> Vuetify </span>
			</a>
			<div class="menu-submenu menu-submenu-fixed">
				<div class="menu-subnav megamenu" style="max-width: 800px;">
					<ul class="menu-content">
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Task Reports </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vuetify/alerts" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Alerts </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/avatars" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Avatars </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/badges" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Badges </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/buttons" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Buttons </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/calendars" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Calendars </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Profit Margins </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vuetify/cards" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Cards </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/chips" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Chips </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/dialog" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Dialog </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/forms-autocompletes" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Autocompletes </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/forms-file-inputs" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> File Inputs </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Staff Management </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vuetify/forms" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Forms </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/forms-selection-controls" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Selection Controls </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/forms-selects" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Selects </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/forms-textareas" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Textareas </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/forms-text-fields" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Text Fields </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
						<li class="menu-item">
							<h3 class="menu-heading menu-toggle">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Tools </span>
								<i class="menu-arrow"></i>
							</h3>
							<ul class="menu-inner">
								<router-link to="/vuetify/tables-simple-tables" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Simple Tables </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/tables-data-tables" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Data Tables </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/tabs" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Tabs </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/timelines" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Timelines </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/tooltips" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Tooltips </span>
										</a>
									</li>
								</router-link>

								<router-link to="/vuetify/treeview" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Treeview </span>
										</a>
									</li>
								</router-link>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</li>
		<li aria-haspopup="true" data-menu-toggle="click" class="menu-item menu-item-submenu menu-item-open-dropdown" v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }">
			<a href="#" class="menu-link menu-toggle">
				<span class="menu-text"> Custom </span>
			</a>
			<div class="menu-submenu menu-submenu-classic">
				<ul class="menu-subnav">
					<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu">
						<a href="#" class="menu-link menu-toggle">
							<i class="menu-icon flaticon2-list-2"></i>
							<span class="menu-text"> Error Pages </span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu menu-submenu-classic menu-submenu-right">
							<ul class="menu-subnav">
								<router-link to="/custom-error/error-1" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Error 1 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-error/error-2" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Error 2 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-error/error-3" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Error 3 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-error/error-4" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Error 4 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-error/error-5" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Error 5 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-error/error-6" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Error 6 </span>
										</a>
									</li>
								</router-link>
							</ul>
						</div>
					</li>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item menu-item-submenu"
						v-bind:class="{
							'menu-item-active': hasActiveChildren('/custom-wizard/wizard'),
						}"
					>
						<a href="#" class="menu-link menu-toggle">
							<i class="menu-icon flaticon2-mail-1"></i>
							<span class="menu-text"> Wizard </span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu menu-submenu-classic menu-submenu-right">
							<ul class="menu-subnav">
								<router-link to="/custom-wizard/wizard-1" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Wizard 1 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-wizard/wizard-2" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Wizard 2 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-wizard/wizard-3" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Wizard 3 </span>
										</a>
									</li>
								</router-link>

								<router-link to="/custom-wizard/wizard-4" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Wizard 4 </span>
										</a>
									</li>
								</router-link>
							</ul>
						</div>
					</li>

					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item menu-item-submenu"
						v-bind:class="{
							'menu-item-active': hasActiveChildren('/custom-plugins'),
						}"
					>
						<a href="#" class="menu-link menu-toggle">
							<i class="menu-icon flaticon2-image-file"></i>
							<span class="menu-text"> Plugins </span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu menu-submenu-classic menu-submenu-right">
							<ul class="menu-subnav">
								<router-link to="/custom-plugins/cropper" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Cropper </span>
										</a>
									</li>
								</router-link>
								<router-link to="/custom-plugins/treeselect" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Treeselect </span>
										</a>
									</li>
								</router-link>
							</ul>
						</div>
					</li>

					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item menu-item-submenu"
						v-bind:class="{
							'menu-item-active': hasActiveChildren('/custom-pages'),
						}"
					>
						<a href="#" class="menu-link menu-toggle">
							<i class="menu-icon far fa-user"></i>
							<span class="menu-text"> Pages </span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu menu-submenu-classic menu-submenu-right">
							<ul class="menu-subnav">
								<router-link to="/custom-pages/profile" v-slot="{ href, navigate, isActive, isExactActive }">
									<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
										<a :href="href" class="menu-link" @click="navigate">
											<i class="menu-bullet menu-bullet-dot"><span></span></i>
											<span class="menu-text"> Profile </span>
										</a>
									</li>
								</router-link>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'KTMenu',
	methods: {
		hasActiveChildren(match) {
			return this.$route['path'].indexOf(match) !== -1;
		},
	},
};
</script>
