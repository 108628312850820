<template>
	<div class="subheader py-2 py-lg-4" v-bind:class="subheaderClasses" id="kt_subheader">
		<div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap" v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
			<div class="d-flex align-items-center flex-wrap mr-1">
				<h5 class="text-dark font-weight-bold my-2 mr-5">
					{{ title }}
				</h5>
				<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
					<li class="breadcrumb-item d-flex">
						<router-link :to="'/'" class="subheader-breadcrumbs-home">
							<i class="flaticon2-shelter text-muted icon-1x"></i>
						</router-link>
					</li>

					<template v-for="(breadcrumb, i) in breadcrumbs">
						<li class="breadcrumb-item d-flex" :key="`${i}-${breadcrumb.id}`">
							<router-link v-if="breadcrumb.route" :key="i" :to="breadcrumb.route" class="text-muted">
								{{ breadcrumb.title }}
							</router-link>
							<span class="text-muted" :key="i" v-if="!breadcrumb.route">
								{{ breadcrumb.title }}
							</span>
						</li>
					</template>
				</ul>
			</div>
			<div class="d-flex align-items-center">
				<template v-for="(action, i) in pageActions">
					<b-button :variant="action.variant" v-on:click="action.click" :disabled="action.processing === true || action.disabled === true" :key="`${i}-${action.id}`" class="mx-2">
						<i v-if="!isEmpty(action.icon)" :class="action.icon"></i>
						{{ action.title }}
						<b-spinner small v-if="action.processing" class="ml-2"></b-spinner>
					</b-button>
				</template>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.custom-v-dropdown {
	&.dropdown-toggle {
		padding: 0;
		&:hover {
			text-decoration: none;
		}

		&.dropdown-toggle-no-caret {
			&:after {
				content: none;
			}
		}
	}

	&.dropdown-menu {
		margin: 0;
		padding: 0;
		outline: none;
		.b-dropdown-text {
			padding: 0;
		}
	}
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'KTSubheader',
	props: {
		pageActions: Array,
		breadcrumbs: Array,
		title: String,
	},
	computed: {
		...mapGetters(['layoutConfig']),

		/**
		 * Check if subheader width is fluid
		 */
		widthFluid() {
			return this.layoutConfig('subheader.width') === 'fluid';
		},

		subheaderClasses() {
			const classes = [];
			const style = this.layoutConfig('subheader.style');
			if (style) {
				classes.push(style);

				if (style === 'solid') {
					classes.push('bg-white');
				}

				if (this.layoutConfig('subheader.fixed')) {
					classes.push('border-top');
				}
			}
			return classes.join(' ');
		},
	},
	methods: {
		isEmpty: function(val) {
			return !(val != null && val !== '');
		},
	},
};
</script>
