<template>
	<ul class="menu-nav">
		<router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon flaticon2-architecture-and-city"></i>
					<span class="menu-text">Dashboard</span>
				</a>
			</li>
		</router-link>

		<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/survey') }" v-if="hasPermission('menu.survey.all') || hasPermission('menu.survey.theme')">
			<a href="#" class="menu-link menu-toggle">
				<i class="menu-icon fas fa-poll"></i>
				<span class="menu-text"> {{ $t('MENU.Survey.surveys') }} </span>
				<i class="menu-arrow"></i>
			</a>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.survey.all')">
				<ul class="menu-subnav">
					<router-link to="/survey/all" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.Survey.allSurveys') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.survey.theme')">
				<ul class="menu-subnav">
					<router-link to="/survey/designs" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.Survey.designs') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
		</li>

		<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/message-administration') }" v-if="hasPermission('menu.messagetemplates.email') || hasPermission('menu.messagetemplates.sms') || hasPermission('menu.messagetemplates.whatsapp')">
			<a href="#" class="menu-link menu-toggle">
				<i class="menu-icon fas fa-comment-alt"></i>
				<span class="menu-text"> {{ $t('MENU.MessageCenter.title') }} </span>
				<i class="menu-arrow"></i>
			</a>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.messagetemplates.email')">
				<ul class="menu-subnav">
					<router-link to="/message-administration/email" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.MessageCenter.email') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.messagetemplates.sms')">
				<ul class="menu-subnav">
					<router-link to="/message-administration/sms" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.MessageCenter.sms') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.messagetemplates.whatsapp')">
				<ul class="menu-subnav">
					<router-link to="/message-administration/whatsapp" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.MessageCenter.whatsapp') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
		</li>

		<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/customer-center') }" v-if="hasPermission('menu.customercenter.overview') || hasPermission('menu.customercenter.memberapp') || hasPermission('menu.customercenter.recommendation')">
			<a href="#" class="menu-link menu-toggle">
				<i class="menu-icon fas fa-users"></i>
				<span class="menu-text"> {{ $t('MENU.CustomerCenter.title') }} </span>
				<i class="menu-arrow"></i>
			</a>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.customercenter.overview')">
				<ul class="menu-subnav">
					<router-link to="/customer-center/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.CustomerCenter.overview') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.customercenter.memberapp')">
				<ul class="menu-subnav">
					<router-link to="/customer-center/member-administration" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('VIEWS.CustomerCenter.MemberAdministration.title') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.customercenter.recommendation')">
				<ul class="menu-subnav">
					<router-link to="/customer-center/recommendation-center" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('VIEWS.CustomerCenter.RecommendationCenter.title') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
		</li>

		<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/administration') }" v-if="hasPermission('menu.admin.company') || hasPermission('menu.admin.organization') || hasPermission('menu.admin.right') || hasPermission('menu.admin.user')">
			<a href="#" class="menu-link menu-toggle">
				<i class="menu-icon fas fa-tools"></i>
				<span class="menu-text"> {{ $t('MENU.Administration.Administration') }} </span>
				<i class="menu-arrow"></i>
			</a>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right">
				<ul class="menu-subnav">
					<router-link to="/administration/company" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasPermission('menu.admin.company')">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.Administration.Company') }} </span>
							</a>
						</li>
					</router-link>
					<router-link to="/administration/organisation" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasPermission('menu.admin.organization') && isOrganisationActive === true">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.Administration.Organisation') }} </span>
							</a>
						</li>
					</router-link>
					<router-link to="/administration/rights" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasPermission('menu.admin.right')">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.Administration.Rights') }} </span>
							</a>
						</li>
					</router-link>
					<router-link to="/administration/user" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasPermission('menu.admin.user')">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> {{ $t('MENU.Administration.Users') }} </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
		</li>

<!--		<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/support-center') }">-->
<!--			<a href="#" class="menu-link menu-toggle">-->
<!--				<i class="menu-icon fas fa-users"></i>-->
<!--				<span class="menu-text"> #Support Center </span>-->
<!--				<i class="menu-arrow"></i>-->
<!--			</a>-->
<!--			<div class="menu-submenu menu-submenu-classic menu-submenu-right" v-if="hasPermission('menu.customercenter.overview')">-->
<!--				<ul class="menu-subnav">-->
<!--					<router-link to="/support-center/overview" v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">-->
<!--							<a :href="href" class="menu-link" @click="navigate">-->
<!--								<i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--								<span class="menu-text"> #Overview </span>-->
<!--							</a>-->
<!--						</li>-->
<!--					</router-link>-->
<!--				</ul>-->
<!--			</div>-->
<!--		</li>-->

		<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/super') }" v-if="hasPermission('super.admin')">
			<a href="#" class="menu-link menu-toggle">
				<i class="menu-icon fas fa-tools"></i>
				<span class="menu-text"> Super Administration </span>
				<i class="menu-arrow"></i>
			</a>
			<div class="menu-submenu menu-submenu-classic menu-submenu-right">
				<ul class="menu-subnav">
					<router-link to="/super/customer" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasPermission('super.admin')">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Neuer Kunde </span>
							</a>
						</li>
					</router-link>
					<router-link to="/super/support" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasPermission('super.admin')">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
							<a :href="href" class="menu-link" @click="navigate">
								<i class="menu-bullet menu-bullet-dot"><span></span></i>
								<span class="menu-text"> Kundensupport </span>
							</a>
						</li>
					</router-link>
				</ul>
			</div>
		</li>
	</ul>
</template>

<script>
import { PRINOR_PERMISSION } from "@/core/services/permissions.service";
import { mapGetters } from 'vuex';

export default {
	name: 'KTMenu',
	computed: {
		...mapGetters(['isOrganisationActive']),
	},
	methods: {
		hasActiveChildren(match) {
			return this.$route['path'].indexOf(match) !== -1;
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.menu(name);
		},
	},
};
</script>
