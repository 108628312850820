<template>
	<div class="topbar-item">
		<div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary" id="kt_quick_panel_toggle" v-on:click="setSeen">
			<div style="float: right; position: absolute; background-color: red; width: 1.1rem; height: 1.1rem; border-radius: 1000px; font-size: .8rem; color: black; right: 5px; top: 5px; z-index: -1;" v-if="countUnseen > 0">
				{{ countUnseen }}
			</div>
			<i class="far fa-bell text-primary"></i>
			<span class="pulse-ring" v-if="countUnseen > 0"></span>
		</div>

		<!-- begin::Quick Panel -->
		<div id="kt_quick_panel" ref="kt_quick_panel" class="offcanvas offcanvas-right pt-5 pb-10" style="overflow: hidden;">
			<!--begin::Header-->
			<div class="offcanvas-header d-flex align-items-center justify-content-between mb-5">
				<h5 class="ml-10"> {{ $t('NOTIFICATION.Titles.notifications') }} </h5>
				<div class="offcanvas-close mt-n1 pr-5">
					<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close">
						<i class="ki ki-close icon-xs text-muted"></i>
					</a>
				</div>
			</div>
			<!--end::Header-->

			<div class="offcanvas-content px-10">
				<div class="scroll pr-7 mr-n7" id="kt_quick_panel_notifications">
					<!--begin::Nav-->
					<div class="navi navi-icon-circle navi-spacer-x-0">
						<template v-for="(item, i) in notifications">
							<a href="" class="navi-item" v-bind:key="i" v-on:click.prevent="action(item)" style="background-color: rgb(243, 246, 249); margin-top: 10px; border-radius: 5px; -webkit-box-shadow: 3px 3px 15px -5px rgba(82, 63, 105, 0.05); box-shadow: 3px 3px 15px -5px rgba(82, 63, 105, 0.05);">
								<div style="float: right; margin-top: -5px; margin-right: -5px;">
									<a href="" v-on:click.prevent="deleteNotification(item.id)" class="btn btn-xs btn-icon btn-light btn-hover-danger" style="background-color: #e3e3e3;">
										<i class="ki ki-close icon-xs text-muted"></i>
									</a>
								</div>
								<div class="navi-link rounded">
									<div class="mr-3" style="align-self: baseline; margin-left: 1rem; margin-top: 0.4rem;">
										<i class="fas fa-chart-bar text-primary" v-if="item.type === 'NewSubscriber'" style="font-size: 1.4rem;"></i>
									</div>
									<div class="navi-text">
										<div class="font-weight-bold font-size-lg">
											{{ $t('NOTIFICATION.Titles.' + item.type) }}
										</div>
										<div class="text-muted" v-if="item.parameter.text_type === 'translation'">
											{{ $t('NOTIFICATION.' + item.content, item.parameter.texts) }}
										</div>
										<div class="text-muted" v-if="item.parameter.text_type === 'text'">
											{{ item.content }}
										</div>
									</div>
								</div>
								<div class="text-muted" style="text-align: right; margin-right: 5px; padding-bottom: 5px;">
									{{ new Date(item.updated_at).toLocaleDateString() + ' ' + new Date(item.updated_at).toLocaleTimeString() }}
									<i class="far fa-clock"></i>
								</div>
							</a>
							<!--end::Item-->
						</template>
					</div>
				</div>
			</div>
		</div>
		<!-- end::Quick Panel -->
	</div>
</template>

<script>
import KTLayoutQuickPanel from '@/assets/js/layout/extended/quick-panel.js';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import JwtService from "@/core/services/jwt.service";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";

export default {
	name: 'Notifications',
	data() {
		return {
			notifications: [],
			connection: null,
			tabIndex: 0,
		};
	},
	computed: {
		countUnseen: function() {
			return this.notifications.filter(value => { return value.seen === false; }).length;
		},
	},
	mounted() {
		// Init Quick Offcanvas Panel
		KTLayoutQuickPanel.init(this.$refs['kt_quick_panel']);

		this.connection = new HubConnectionBuilder()
			.withUrl(process.env.VUE_APP_URL_API + "/hubs/notification", { accessTokenFactory: () => JwtService.getToken() })
			.configureLogging(LogLevel.Information)
			.build();

		this.connection.on("ReceiveNotifications", data => {
			this.notifications = data;
		});

		this.connection.start().then(() => {
			this.connection.invoke("AddToOrganizationGroup");
			this.connection.invoke("AddToCompanyGroup");
			this.connection.invoke("GetNotifications");
		});
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		deleteNotification: function(id) {
			this.connection.invoke("DeleteNotification", id);
		},
		setSeen: function() {
			this.notifications.forEach(notification => {
				notification.seen = true;
			});

			this.connection.invoke("NotificationSeen");
		},
		action: function(notification) {
			if (notification.action === "report" && this.hasPermission('action.survey.report')) {
				this.$router.push({ name: 'report', params: { id: notification.parameter.id } });
				KTLayoutQuickPanel.hide();
			}
		}
	},
};
</script>
